<template>
  <div class="mt-4 h-100 card shadow w-100 p-9 text-center bg-gradient-danger">
    <h1 class="text-white ">You are not authorized to access this page</h1>
    <h3 class="text-white ">Your session probably expired, please login again</h3>
    <button class="btn btn-warning float-left" @click="$router.push('login')" >Login</button>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Unauthorized",
  components: {
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  mounted() {},
};
</script>
